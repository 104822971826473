import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot, CanActivateChild, Route, ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { SessionsService } from '../../sessions/sessions.service';


@Injectable()
export class OAuthGuard implements CanActivate, CanActivateChild {

    constructor(
        protected router: Router,
        protected sessionsService: SessionsService,
    ) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {

        if (localStorage.getItem('isoAuthRequired') === "false" || localStorage.getItem('isoAuthRequired') == 'null') {
            return true;
        }else{
            if(localStorage.getItem('isSSOUser') == "true"){
                this.router.navigate([localStorage.getItem('landingPage')])
            }else{
                return true;
            }
        }
        
    }

    canActivateChild(childroute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
        return this.canActivate(childroute, state);
    }

}


